import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


import Seo from "../components/seo"
import ContactForm from "../components/contactform"
import Footer from "../components/footer"

import * as styles from "../styles/pages/index.module.css"

import imgbannergap from "../images/index/bannergap.png"
import imgbox from "../images/boxholder.png"

const imgbanner = "../images/index/banner.jpg"
const imgfooter = "../images/index/footer.jpg"
const imglogo = "../images/logo.png"


const HomePage = () => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
			}
		`
	)
	const contactfields = [
		{
			"name": "formname",
			"label": "Name",
			"value": "",
			"required": true,
		},
		{
			"name": "formorganization",
			"label": "Organization",
			"value": "",
			"required": true,
		},
		{
			"name": "formemail",
			"label": "Email",
			"type": "email",
			"value": "",
			"required": true,
		},
		{
			"name": "formmobile",
			"label": "Mobile",
			"type": "tel",
			"value": "",
		},
		{
			"name": "formmsg",
			"label": "Message",
			"value": "",
			"required": true,
		}
	];
	var structuredData = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"sameAs": [
			"https://www.instagram.com/iamnetph/",
			"https://twitter.com/iamnetph/",
			"https://www.facebook.com/iamnetph/"
		],
		"url": site.siteMetadata.siteUrl,
		"description": site.siteMetadata.description,
		"mainEntityOfPage": site.siteMetadata.title,
		"address": {
			"@type": "PostalAddress",
			"addressRegion": "Metro Manila",
			"postalCode": "1403",
			"addressCountry": "PH",
			"addressLocality": "Caloocan City",
			"streetAddress": "704 Rizal Ave. ext."
		},
		"email": "info@iamnet.comph",
		"name": site.siteMetadata.title,
		"image": site.siteMetadata.siteUrl+"/images/logo.png",
		"logo": site.siteMetadata.siteUrl+"/images/logo-square.png",
		"@id": site.siteMetadata.siteUrl
	}
	var numyears = Math.floor(((new Date()).getFullYear() - 2011)/5)*5;
	return (<>
			<Seo title={site.siteMetadata.title} description={site.siteMetadata.description} canonicalpath={''} structuredData={structuredData} />
			<main>
				<div className={styles.mainholder}>
					<StaticImage layout="fullWidth"  className={styles.topbanner} src={imgbanner} alt={""} />
					<div className={styles.maincontent}>
						<img className={styles.topbannergap} src={imgbannergap} alt={""} />
						<div className={styles.mainlogoholder+" "+styles.mainbgcolor+" text-centered"}>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
							<StaticImage layout="fullWidth"  className={styles.mainlogo} src={imglogo} alt={site.siteMetadata.title} />
							<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
							<h2 className={"font-size-medium"}>
								We Enable Through Technology
							</h2>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						</div>
						<div className={styles.mainsubsection+" "+styles.mainaltbgcolor+" text-centered"}>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
							<div>
								<div className={styles.mainsubsectionmargin}>
									IAMNET has over {numyears} years of experience in various fields, not just software development.  Our interdisciplinary perspective have yeilded practical and realistic solutions.  We've worked with multinational, private and public entities, and have  deployments at the nationwide level.
								</div>
							</div>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						</div>
						<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
							<h2 className={"font-size-medium"}>
								Development Partners
							</h2>
							<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
							<div className={styles.mainsubsectionmargin}>
								We value your experience and insights. Let's empower your team by augmenting your existing processes with the most effective tools.
							</div>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						</div>
						<StaticImage layout="fullWidth"  className={styles.bottombanner} src={imgfooter} alt={""} />
						<div className={styles.mainsubsection+" "+styles.mainaltbgcolor+" text-centered"}>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
							<h2 className={styles.mainaltbgcolor+" font-size-medium"}>
								Sit with us
							</h2>
							<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
							<div className={styles.mainsubsectionmargin}>
								Let's share experiences to develop a better way of doing things.
							</div>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						</div>
						<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
							<ContactForm
									submitURL={"https://webform.api.iamnet.ph/iamnetinfo"}
									fields={contactfields}
									sendLabel={true}
								/>
							<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						</div>
						<Footer />
					</div>
				</div>
			</main>
		</>)

}

export default HomePage

